import { lazy } from "react";
import { MODAL } from "redux/slices/modal";

const DisableModal = lazy(() => import("./DisableModal"));
const ConfirmationForm = lazy(() => import("./ConfirmationForm"));
const ConfirmationModal = lazy(() => import("./ConfirmationModal"));
const ExportTableOptions = lazy(() => import("./ExportTableOptions"));

const CarMovementFinish = lazy(() => import("./CarMovementFinish"));
const UpdateShift = lazy(() => import("./UpdateShift"));
const AddDriver = lazy(() => import("./AddDriver"));
const AddPartner = lazy(() => import("./AddPartner"));
const OTPForm = lazy(() => import("./OTPForm"));
const SendPushNotification = lazy(() => import("./SendPushNotification"));
const RevenueDetails = lazy(() => import("./RevenueDetails"));
const AddExpense = lazy(() => import("./AddExpense"));
const UploadRevenue = lazy(() => import("./UploadRevenue"));
const RevenueDates = lazy(() => import("./RevenueDates"));
const SendEmail = lazy(() => import("./SendEmail"));

export { default } from "./AppModal";

export type ModalMapper = {
  [key in MODAL]: "" | JSX.Element;
};

export const modalMapper: ModalMapper = {
  DISABLE_DRIVER: <DisableModal />,
  CONFIRMATION_FORM: <ConfirmationForm />,
  CONFIRMATION_MODAL: <ConfirmationModal />,
  EXPORT_TABLE_OPTIONS: <ExportTableOptions />,
  FINISH_CAR_MOVEMENT: <CarMovementFinish />,
  UPDATE_SHIFT: <UpdateShift />,
  ADD_DRIVER: <AddDriver />,
  ADD_PARTNER: <AddPartner />,
  OTP_FORM: <OTPForm />,
  PUSH_NOTIFICATION: <SendPushNotification />,
  REVENUE_DETAILS: <RevenueDetails />,
  ADD_EXPENSE: <AddExpense />,
  UPLOAD_REVENUE: <UploadRevenue />,
  REVENUE_DATE: <RevenueDates />,
  SEND_EMAIL: <SendEmail />,
};
