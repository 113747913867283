import "./App.css";
// e0d9bb8070ce0054c9d9ecb6e82cb58fTz0wLEU9MzI0NzIxNDQwMDAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y

import { store } from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import AppRoutes from "routes/AppRoutes";
import Toaster from "components/atoms/Toaster";
import Header from "components/templates/Header";
import AppModal from "components/templates/AppModal";
import ErrorBoundary from "components/atoms/ErrorBoundary";
import RegisterAppDispatch from "components/atoms/RegisterAppDispatch";
import { LicenseInfo } from "@mui/x-license";

import theme from "./theme";
import createCache from "@emotion/cache";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { CacheProvider } from "@emotion/react";
// import dateAdapter from "@mui/lab/AdapterDateFns";
// import { ScopedCssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { config } from "config";
import useEffectOnce from "hooks/useEffectOnce";
import UserService from "services/user.service";
import ShiftService from "services/shift.service";
import Layout from "components/organisms/Layout";
import "dayjs/locale/de";
import "dayjs/locale/tr";
import "dayjs/locale/ar";

const cache = createCache({ key: "css", prepend: true });
LicenseInfo.setLicenseKey(config.MUI_LICENSE_KEY as string);

export default function App() {
  const lang = localStorage.getItem("i18nextLng") || "en";

  useEffectOnce(() => {
    if (localStorage.getItem("token")) {
      UserService.getLoggedUser();
      ShiftService.activeShift();
    }
  });
  return (
    <ErrorBoundary>
      <SnackbarProvider
        maxSnack={5}
        hideIconVariant
        preventDuplicate
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        iconVariant={{
          success: "✅",
          error: "✖️",
          warning: "⚠️",
          info: "ℹ️",
        }}
      >
        <Toaster />
        <ErrorBoundary>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CacheProvider value={cache}>
              <ThemeProvider theme={theme(lang)}>
                <CssBaseline />
                {/* <ScopedCssBaseline> */}
                <Provider store={store}>
                  <RegisterAppDispatch />
                  <BrowserRouter>
                    {/* <ErrorBoundary>
                      <Header />
                    </ErrorBoundary> */}
                    <ErrorBoundary>
                      <AppModal />
                    </ErrorBoundary>
                    <Layout>
                      <ErrorBoundary>
                        <AppRoutes />
                      </ErrorBoundary>
                    </Layout>
                  </BrowserRouter>
                </Provider>
                {/* </ScopedCssBaseline> */}
              </ThemeProvider>
            </CacheProvider>
          </LocalizationProvider>
        </ErrorBoundary>
      </SnackbarProvider>
    </ErrorBoundary>
  );
}
