import { config } from "config";
import io from "socket.io-client";
import { log } from "../utils/logger.util";
import { getAppDispatch } from "utils/dispatch.util";
import LocationService from "./location.service";

let socket: any;

const SocketService = {
  connect: ({ _id, first_name, last_name }: any) => {
    const dispatch = getAppDispatch();
    let data = JSON.stringify({
      id: _id,
      role: "admin",
      name: `${first_name} ${last_name}`,
    });

    socket = io(config.SOCKET_URL, { query: { data } });

    log("socket init");

    socket.on("connect", () => {
      log("socket connected", socket.id);
    });

    socket.on("disconnect", (reason: any) => {
      log("socket disconnected", reason);
    });

    socket.on("connect_error", (e: any) => {
      log("socket connect_error", e.message);
      // socket.connect();
    });

    socket.onAny((event: any, ...args: any) => {
      log("socket event", { event, args });
    });

    socket.on("LOCATION_UPDATE_ALL", (data: any) => {
      if (data) {
        console.log("data", data);
        LocationService.updateDriverLocation(data);
      }
    });
  },

  disconnect: () => {
    if (!socket) return;

    socket.disconnect();
  },
};

export default SocketService;
