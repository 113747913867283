import http from "./http.service";
import Promisable from "./promisable.service";
import { modalActions } from "redux/slices/modal";
import { NavigateFunction } from "react-router-dom";
import { loaderActions } from "redux/slices/loader";
import { shiftActions } from "redux/slices/shift";
import { getAppDispatch } from "utils/dispatch.util";
import { formLoaderActions } from "redux/slices/formLoader";
import UserService from "./user.service";
import { change } from "redux-form";

const url = "/admin/shift";

const ShiftService = {
  startShift: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/start`, data)
    );

    if (success) {
      const { shift } = success.data.data;

      dispatch?.(shiftActions.driverShift(shift));
      UserService.getLoggedUser();

      dispatch?.(change("DriverShiftForm", "type", ""));
      dispatch?.(change("DriverShiftForm", "vehicle", ""));
      dispatch?.(change("DriverShiftForm", "mileageStart", ""));
      dispatch?.(change("DriverShiftForm", "mileageStartImage", ""));
      dispatch?.(change("DriverShiftForm", "vehicleStartImages", []));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  endShift: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/end`, data)
    );

    if (success) {
      UserService.getLoggedUser();

      dispatch?.(shiftActions.driverShift(null));
      dispatch?.(change("DriverShiftForm", "mileageEnd", ""));
      dispatch?.(change("DriverShiftForm", "mileageEndImage", ""));
      dispatch?.(change("DriverShiftForm", "vehicleEndImages", []));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  activeShift: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/activeShift`)
    );

    if (success) {
      const { shift } = success.data.data;
      dispatch?.(shiftActions.driverShift(shift));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  addShift: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/create`, data)
    );

    if (success) {
      const { shift } = success.data.data;
      dispatch?.(shiftActions.addShift(shift));
      dispatch?.(modalActions.closeModal());

      navigate?.(`/shifts`);
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  updateShift: async (id: string, data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/update/${id}`, data)
    );

    if (success) {
      const { shift } = success.data.data;
      dispatch?.(shiftActions.updateShift({ id, shift }));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  getShift: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/getOne/${id}`)
    );

    if (success) {
      const { shift } = success.data.data;
      dispatch?.(shiftActions.setShift(shift));
    } else dispatch?.(shiftActions.setShift(null));

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  getAllShifts: async (data: any = { all: "true" }) => {
    const dispatch = getAppDispatch();

    if (data?.all === true) dispatch?.(modalActions.setLoading(true));
    else dispatch?.(shiftActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getAll`, data)
    );

    if (success) {
      const { totalCount, shifts } = success.data.data;
      if (data?.all === true)
        dispatch?.(
          modalActions.updateData({
            table_data: shifts.map((shift: any) => ({
              Name: `${shift.fullName}`,
              Phone: shift.phone,
              Email: shift.email,
              Company: shift.company?.name,
              Status: shift.status,
              "Status Reason": shift.status_reason,
              Languages: shift.languages.join(", "),
            })),
          })
        );
      else
        dispatch?.(
          shiftActions.setShifts({ count: totalCount, shifts: shifts })
        );
    }

    if (data?.all === true) dispatch?.(modalActions.setLoading(false));
    else dispatch?.(shiftActions.setLoading(false));
    return [success, error];
  },
  getAllShiftsByVehicle: async (id: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(shiftActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/getAllByVehicle/${id}`)
    );

    if (success) {
      const { shifts } = success.data.data;
      dispatch?.(shiftActions.setShifts({ count: 0, shifts }));
    }

    dispatch?.(shiftActions.setLoading(false));
    return [success, error];
  },

  deleteShift: async (id: string, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`)
    );

    if (success) {
      dispatch?.(shiftActions.deleteShift(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default ShiftService;
