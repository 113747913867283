import { DriverState } from ".";
import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";

const default_page_size = config.PAGE_SIZE;
const initialState: DriverState = {
  count: 0,
  refresh: 0,
  drivers: [],
  driver: null,
  loading: true,
  driverOptions: [],
  driverConnectedOptions: [],
  uberDrivers: [],
  boltDrivers: [],

  current_filters: {},
  refreshLoader: false,
  filters: {
    page: 1,
    page_size: default_page_size,
    sortBy: "createdAt",
    sortDirection: -1,
  },
};

export const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    clear: (state) => {
      state.driverOptions = [];
      state.drivers = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addDriver: (state, action) => {
      state.drivers.unshift(action.payload);
    },
    updateDriver: (state, action) => {
      const { id, driver } = action.payload;
      state.drivers.every(({ _id }, i) => {
        if (id === _id) {
          state.drivers[i] = driver;
          return false;
        }
        return true;
      });
    },
    setDriver: (state, action) => {
      state.driver = action.payload;
    },
    setDrivers: (
      state,
      action: PayloadAction<{ drivers: any[]; count: number }>
    ) => {
      const { count, drivers } = action.payload;
      let options: SelectOption[] = [];

      drivers?.forEach(({ _id, fullName }: any) => {
        const option = { value: _id, label: `${fullName}` };
        options.push(option);
      });

      state.count = count;
      state.drivers = drivers;
      state.refreshLoader = false;
      // state.driverOptions = options;
    },
    setPartnerDrivers: (state, action) => {
      const drivers = action.payload;
      let options: SelectOption[] = [];
      drivers.forEach(({ _id, fullName }: any) =>
        options.push({ value: _id, label: `${fullName}` })
      );
      // state.driverOptions = options;
    },
    setDriversOptions: (state, action) => {
      state.driverOptions = action.payload;
    },
    setDriversConnectedOptions: (state, action) => {
      state.driverConnectedOptions = action.payload;
    },
    setUberDrivers: (state, action) => {
      state.uberDrivers = action.payload;
    },
    setBoltDrivers: (state, action) => {
      state.boltDrivers = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
    deleteDriver: (state, action) => {
      const id = action.payload;
      state.drivers.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.drivers.splice(i, 1);
          return false;
        }
        return true;
      });
    },
  },
});

const driverReducer = driverSlice.reducer;

export const driverActions = driverSlice.actions;
export default driverReducer;
